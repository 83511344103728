import { MONTHS } from '@/data/constants'
import { toZonedTime } from 'date-fns-tz'

//Format notification date and time
const formatNotificationTime = time => {
  const now = new Date()
  const difference = now - time
  if (difference <= 60000) {
    return 'now'
  } else if (difference > 1000 && difference <= 3600000) {
    let mins = difference / 60000
    return `${Math.round(mins)} mins ago`
  } else if (difference > 3600000 && difference <= 86400000) {
    let hrs = difference / 3600000
    return `${Math.round(hrs)} hrs ago`
  } else {
    return `${MONTHS[time?.getMonth()]} ${time?.getDate()}`
  }
}

//Converting UTC date times into ET date times
const convertUTCToET = utcTime => {
  if (utcTime) {
    const etDate = toZonedTime(utcTime, 'America/New_York')
    return etDate
  }
}

//Format dueDate to MM/DD/YY from accountSummary
const formatDueDate = data => {
  if (!data?.dueDate) return 'N/A'

  const date = new Date(data.dueDate)

  // Ensure the date is valid
  if (isNaN(date)) return 'Invalid Date'

  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  }).format(date)
}

export { formatNotificationTime, convertUTCToET, formatDueDate }
