export const END_POINTS = {
  ME: '/api/auth/me',
  SEND_OTP: '/api/otp',
  UPDATE_COGNITO_PHONE: '/api/customer/phone/update-cognito-phone',
  ON_BOARDING: {
    CARD_APPLICATION: '/api/account/register',
    DISMISS_BANNER: '/api/customer/',
    DISMISS_FIRST_LOGIN_MODAL: '/api/customer/first-login-modal',
    ADD_VEHICLES: '/api/vehicles',
  },
  REFERENCE_DATA: {
    GET_VEHICLE_MAKES: '/api/reference/vehicle-makes',
    GET_VEHICLE_MODELS: '/api/reference/vehicle-models',
  },
  PAYMENT: {
    PLAID: {
      CREATE_LINK_TOKEN: '/api/plaid/create-link-token',
      EXCHANGE_PUBLIC_TOKEN: '/api/plaid/exchange-public-token',
      HAS_MONITORED_BANK_ACCOUNT: '/api/plaid/has-monitored-bank-account',
    },
    BANK_ACCOUNTS: '/api/bank-accounts',
    SCHEDULE_PAYMENT: '/api/schedule-payment',
    SCHEDULE_RECURRING_PAYMENT: '/api/schedule-recurring-payment',
    RECURRING_PAYMENT: '/api/recurring-payment',
    ACH_TRANSFERS: '/api/ach-transfers',
    HAS_ONE_TIME_PAYMENTS: '/api/has-one-time-payments',
    PAYMENT_METHODS: '/api/payment-methods',
  },
  TRANSACTIONS: {
    BASE: '/api/transactions',
  },
  STATEMENTS: {
    ACCOUNT_SUMMARY: '/api/statements/account-summary',
    STATEMENTS_HISTORY: '/api/statements',
    PDF_STATEMENT: '/api/statements',
  },
  DOCUMENT_UPLOAD: {
    SESSION: '/api/document-upload/session',
    TOKEN: '/api/document-upload/token',
    SESSION_START: '/api/document-upload/session/start',
    UPLOAD_LINK: '/api/document-upload/link',
    SESSION_END: '/api/document-upload/session/end',
  },
  CARD_MANAGEMENT: {
    ACTIVATE_CARD: '/api/account/activate-card',
    UPDATE_CARD_STATUS: '/api/account/update-card-status',
    VIEW_SECURE_CARD_DETAILS: '/api/account/card-details',
    REISSUE_CARD: '/api/account/reissue-card',
  },
  VEHICLES: {
    GET_VEHICLES: '/api/vehicles',
    DELETE_VEHICLES: '/api/vehicles/',
    UPDATE_VEHICLES: '/api/vehicles/',
    CHECK_VIN_AVAILABILITY: '/api/vehicles/validate-vin/',
  },
  REWARDS: {
    GET_REWARDS_SUMMARY: '/api/account/rewards-summary',
    REWARD_ACTIVITIES: '/api/account/rewards-activities',
    REDEEMABLE_POINTS: '/api/account/redeemable-points',
    REDEEM_POINTS: '/api/account/redeem',
    GET_REWARDS_POINTS: '/api/account/rewards-points',
  },
  DEALERSHIP: {
    GET_ALL_DEALERSHIPS: '/api/dealerships',
  },
}
