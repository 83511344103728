import CheckOutline from '@/assets/icons/check-outlined'
import { ROUTE_HOME } from '@/routes/paths'
import { ROUTE_REWARDS } from '@/routes/paths'
import RewardsBenefitsCard_1 from '@/assets/images/rewards-benefits-card_1.png'
import RewardsBenefitsCard_2 from '@/assets/images/rewards-benefits-card_2.png'
import RewardsBenefitsCard_3 from '@/assets/images/rewards-benefits-card_3.png'
import RewardsBenefitsCard_4 from '@/assets/images/rewards-benefits-card_4.png'
import RewardsBenefitsCard_5 from '@/assets/images/rewards-benefits-card_5.png'
import RewardsBenefitsCard_6 from '@/assets/images/rewards-benefits-card_6.png'
import SuccessOutline from '@/assets/icons/success-submission'
import VerificationPrompt from '@/assets/icons/verification-prompt-Icon'

export const routes = [
  { name: 'Home', path: 'home' },
  { name: 'Transactions', path: 'transactions/all-transactions' },
  { name: 'Payments', path: 'payments/make-payment' },
  { name: 'Rewards', path: 'rewards/benefits' },
  { name: 'Vehicles', path: 'vehicles/manage-vehicles' },
]

export const CARD_APPLICATION_STEPS = [
  {
    title: 'Create account',
    description: 'Create your Fasten Rewards account',
    formDescription:
      'To start your Fasten Rewards application, please enter your email and create a password, or use Google Single Sign-On for convenience.',
  },
  {
    title: 'Personal information',
    description: 'Enter your personal details',
    formDescription:
      'Please fill in your personal details below. This information is essential for verifying your identity and eligibility.',
  },
  {
    title: 'Financial information',
    description: 'Enter your financial details',
    formDescription:
      'Please provide your financial information, including income and resident type, to assess your creditworthiness and determine your eligibility.',
  },
  {
    title: 'Add your vehicle',
    description: 'Enter your vehicle details',
    formDescription:
      'Please enter details about your vehicle, including make, model, and year, to complete the credit card application process.',
  },
  {
    title: 'Agree to terms',
    description: 'Agree to the terms and conditions',
    formDescription: 'Lorem ipsum dolor sit amet consectetur. Morbi metus semper integer lectus tristique neque.',
  },
]

export const ACCOUNT_PAGE_TABS = {
  'manage-card': {
    title: 'Manage Card',
  },
  'account-information-and-security': {
    title: 'Account Information & Security',
  },
  more: {
    title: 'More',
  },
}

export const TRANSACTIONS_PAGE_TABS = {
  'all-transactions': {
    title: 'All Transactions',
  },
  statements: {
    title: 'Statements',
  },
}

export const PAYMENTS_PAGE_TABS = {
  'make-payment': {
    title: 'Make a Payment',
  },
  'payment-history': {
    title: 'Payment History',
  },
  'auto-pay': {
    title: 'AutoPay',
  },
  'payment-methods': {
    title: 'Payment Methods',
  },
  'pay-by-check': {
    title: 'Pay by Check',
  },
}

export const REWARDS_PAGE_TABS = {
  benefits: {
    title: 'Benefits',
  },
  'my-rewards': {
    title: 'My Rewards',
  },
}

export const VEHICLES_PAGE_TABS = {
  'manage-vehicles': {
    title: 'Manage Vehicles',
  },
}

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
//TODO remove CARD_APPLICATION_DENIED
export const CARD_APPLICATION_DENIED = 'DENIED'
export const CARD_APPLICATION_PENDING = 'PENDING'
export const CARD_APPLICATION_APPROVED = 'APPROVED'
export const CARD_APPLICATION_IN_REVIEW = 'IN_REVIEW'

export const CARD_STATUS_ACTIVE = 'ACTIVE'
export const CARD_STATUS_SUSPENDED = 'SUSPENDED'
export const CARD_STATUS_CLOSED = 'CLOSED'
export const CARD_STATUS_ACTIVATION_REQUIRED = 'ACTIVATION_REQUIRED'

export const EMAIL_VERIFICATION_PROMPT = 'VERIFICATION_PROMPT'
export const EMAIL_VERIFIED = 'VERIFIED'
export const EMAIL_VERIFICATION_EXPIRED = 'VERIFICATION_EXPIRED'

export const CARD_APPLICATION_STATUSES = {
  [CARD_APPLICATION_DENIED]: {
    title: 'Application submitted successfully',
    icon: SuccessOutline,
    description: 'Thank you for your request.',
    subDescription: 'A decision regarding your application has been sent to you via email.',
    cta: 'Return to Fasten Rewards',
    ctaPathname: ROUTE_REWARDS,
  },
  [CARD_APPLICATION_PENDING]: {
    title: 'Application submitted successfully',
    icon: SuccessOutline,
    description: 'Thank you for your request.',
    subDescription:
      'We need to review your application a little longer. We will let you know of our decision as soon as possible.',
    cta: 'Return to Fasten Rewards',
    ctaPathname: ROUTE_HOME,
  },
  [CARD_APPLICATION_APPROVED]: {
    title: 'Application approved',
    icon: CheckOutline,
    description: 'Congratulations! We’ve approved your application for the Fasten™ Rewards Visa Card.',
    subDescription:
      "You'll receive your new card and account details via mail within the next two weeks. However, you don't have to wait to start using your benefits! Access your digital card immediately, starting today.*",
    cta: 'Access your card',
    ctaPathname: ROUTE_HOME,
  },
  [CARD_APPLICATION_IN_REVIEW]: {
    title: 'Application submitted successfully',
    icon: SuccessOutline,
    description: 'Thank you for your request.',
    subDescription:
      'We need to review your application a little longer. We will let you know of our decision as soon as possible.',
    cta: 'Return to Fasten Rewards',
    ctaPathname: ROUTE_HOME,
  },
}

export const EMAIL_VERIFICATION_STATUSES = {
  [EMAIL_VERIFICATION_PROMPT]: {
    title: 'Please Verify Your Email Address',
    icon: VerificationPrompt,
    description:
      'Before accessing the dashboard, you must verify your email address. Please check your email for the verification link or request a new one below.',
    cta: 'Resend',
  },
  [EMAIL_VERIFIED]: {
    title: 'Your email address has been verified',
    icon: CheckOutline,
    description: '',
    cta: 'Sign in',
  },
  [EMAIL_VERIFICATION_EXPIRED]: {
    title: 'Expired verification link',
    icon: CheckOutline,
    description:
      'The email verification link you used has expired. Please sign in to your account to request a new verification link.',
    cta: 'Resend',
  },
}

export const REWARDS_CAROUSEL_CONTENTS = [
  {
    title: '1X',
    description: 'on Everyday spend',
  },
  {
    title: '2X',
    description: 'on Auto Loans & Leases*',
  },
  {
    title: '2x',
    description: 'on In-Network Dealership Down Payment',
  },
  {
    title: '3x',
    description: 'on In-Network Dealership Service, Repairs, Merchandise',
  },
]

export const REWARDS_CARDS = [
  {
    title: 'Monthly Auto Loan or Lease Payment',
    description:
      'Use your Fasten Rewards Visa card everywhere you go to earn 2x points monthly rewards.  about time you were rewarded! for paying your auto loan or lease on time.* ',
    image: RewardsBenefitsCard_1,
    linkUrl: 'url_to_destination_1',
  },
  {
    title: 'In-Network Dealership Service',
    description:
      'Tune-ups, oil changes, tire rotations - all in-network dealership service and maintenance earns 3x points.  Schedule your appointment today with your favorite dealership!',
    image: RewardsBenefitsCard_2,
    linkUrl: 'url_to_destination_1',
  },
  {
    title: 'Gas, Car Wash, Tolls, Parking, EV Charging',
    description:
      'Earn 3x points every time you fill up, wash your car, charge it up, or pay for tolls or parking.  Fasten Rewards goes the extra mile with you!',
    image: RewardsBenefitsCard_3,
    linkUrl: 'url_to_destination_1',
  },
  {
    title: 'Buy Your Dream Car',
    description:
      '2x points are yours when you put your down payment on your Fasten Rewards Visa card at an in-network dealership.  Get rewarded and get a new car!',
    image: RewardsBenefitsCard_4,
    linkUrl: 'url_to_destination_1',
  },
  {
    title: 'Everyday Spend',
    description:
      'Earn points on all your other spend too!  We love to reward you for what you spend on your car and what you spend on everything else.  All those points add up fast and turn into great rewards!',
    image: RewardsBenefitsCard_5,
    linkUrl: 'url_to_destination_1',
  },
  {
    title: 'Bonus Rewards',
    description:
      'It pays to be a Fasten Rewards member!  Keep your eye on your email for opportunities to earn bonus points, maximize point accelerators,  participate in exclusive member giveaways, and more!',
    image: RewardsBenefitsCard_6,
    linkUrl: 'url_to_destination_1',
  },
]

export const US_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
]

export const PAYMENT_DAYS = [
  { value: 1, label: '1st' },
  { value: 2, label: '2nd' },
  { value: 3, label: '3rd' },
  { value: 4, label: '4th' },
  { value: 5, label: '5th' },
  { value: 6, label: '6th' },
  { value: 7, label: '7th' },
  { value: 8, label: '8th' },
  { value: 9, label: '9th' },
  { value: 10, label: '10th' },
  { value: 11, label: '11th' },
  { value: 12, label: '12th' },
  { value: 13, label: '13th' },
  { value: 14, label: '14th' },
  { value: 15, label: '15th' },
  { value: 16, label: '16th' },
  { value: 17, label: '17th' },
  { value: 18, label: '18th' },
  { value: 19, label: '19th' },
  { value: 20, label: '20th' },
  { value: 21, label: '21st' },
  { value: 22, label: '22nd' },
  { value: 23, label: '23rd' },
  { value: 24, label: '24th' },
  { value: 25, label: '25th' },
  { value: 26, label: '26th' },
  { value: 27, label: '27th' },
  { value: 28, label: '28th' },
]

export const VEHICLE_BRANDS = [
  { value: 'acura', label: 'Acura' },
  { value: 'alfa-romeo', label: 'Alfa Romeo' },
  { value: 'aston-martin', label: 'Aston Martin' },
  { value: 'audi', label: 'Audi' },
  { value: 'bentley', label: 'Bentley' },
  { value: 'bmw', label: 'BMW' },
  { value: 'buick', label: 'Buick' },
  { value: 'cadillac', label: 'Cadillac' },
  { value: 'chevrolet', label: 'Chevrolet' },
  { value: 'chrysler', label: 'Chrysler' },
  { value: 'dodge', label: 'Dodge' },
  { value: 'ferrari', label: 'Ferrari' },
  { value: 'fiat', label: 'Fiat' },
  { value: 'ford', label: 'Ford' },
  { value: 'genesis', label: 'Genesis' },
  { value: 'gmc', label: 'GMC' },
  { value: 'honda', label: 'Honda' },
  { value: 'hyundai', label: 'Hyundai' },
  { value: 'infiniti', label: 'Infiniti' },
  { value: 'jaguar', label: 'Jaguar' },
  { value: 'jeep', label: 'Jeep' },
  { value: 'kia', label: 'Kia' },
  { value: 'lamborghini', label: 'Lamborghini' },
  { value: 'land-rover', label: 'Land Rover' },
  { value: 'lexus', label: 'Lexus' },
  { value: 'lincoln', label: 'Lincoln' },
  { value: 'lotus', label: 'Lotus' },
  { value: 'maserati', label: 'Maserati' },
  { value: 'mazda', label: 'Mazda' },
  { value: 'mclaren', label: 'McLaren' },
  { value: 'mercedes-benz', label: 'Mercedes-Benz' },
  { value: 'mercury', label: 'Mercury' },
  { value: 'mini', label: 'Mini' },
  { value: 'mitsubishi', label: 'Mitsubishi' },
  { value: 'nissan', label: 'Nissan' },
  { value: 'oldsmobile', label: 'Oldsmobile' },
  { value: 'pagani', label: 'Pagani' },
  { value: 'plymouth', label: 'Plymouth' },
  { value: 'pontiac', label: 'Pontiac' },
  { value: 'porsche', label: 'Porsche' },
  { value: 'ram', label: 'Ram' },
  { value: 'rolls-royce', label: 'Rolls-Royce' },
  { value: 'saab', label: 'Saab' },
  { value: 'saturn', label: 'Saturn' },
  { value: 'scion', label: 'Scion' },
  { value: 'subaru', label: 'Subaru' },
  { value: 'suzuki', label: 'Suzuki' },
  { value: 'tesla', label: 'Tesla' },
  { value: 'toyota', label: 'Toyota' },
  { value: 'volkswagen', label: 'Volkswagen' },
  { value: 'volvo', label: 'Volvo' },
  { value: 'other', label: 'Other' },
]
export const MILEAGE_BRANDS = [
  { value: 0, label: 'Under 20,000' },
  { value: 1, label: '20,001 - 50,000' },
  { value: 2, label: '50,001 - 100,000' },
  { value: 3, label: '100,001+' },
]

export const DEALERSHIP_BRANDS = [
  { value: 'Toyota-Asheboro', label: 'Modern Toyota of Asheboro' },
  { value: 'Mazda', label: 'Modern Mazda of Burlington' },
  { value: 'Chevrolet', label: 'Modern Chevrolet of Burlington' },
  { value: 'Toyota', label: 'Modern Toyota' },
  { value: 'other', label: 'Other' },
]

export const SUPPORT_PAGE_TABS = {
  faq: {
    title: 'FAQ',
  },
  'contact-us': {
    title: 'Contact Us',
  },
}

export const OPTIONS = {
  VEHICLE_EXPENSES: 'vehicle-expenses',
  AMAZON_GIFT_CARD: 'amazon-gift-card',
  CASH: 'cash',
}

export const REWARDS_RULES = {
  STATEMENT_CREDIT_REDEMPTION: 'STATEMENT_CREDIT_REDEMPTION',
  VEHICLES_REDEMPTION: 'VEHICLES_REDEMPTION',
  AMAZON_GIFT_REDEMPTION: 'AMAZON_GIFT_REDEMPTION',
}
export const REDEMPTION_DISPLAY_TYPES = ['CASH_REDEMPTION', 'AMAZON_GIFT_REDEMPTION', 'VEHICLE_REDEMPTION']

export const REPLACE_PHYSICAL_CARD = {
  CARD_NOT_FOUND: 'No Fasten Card Found',
}
export const RESET_PASSWORD = {
  INVALID_OR_EXPIRED_LINK: 'Link invalid or expired!',
  IS_CURRENT_PWD_REUSE: 'PasswordHistoryPolicyViolationException',
}

export const MFA_CONFIG_FLOWS = {
  DEFAULT: 'DEFAULT',
  ENFORCED: 'ENFORCED',
}
