import { useAuthQuery } from '@/hooks/use-auth-query'
import { rewardsApi } from '@/api'

function useGetRewardBalance(period) {
  return useAuthQuery({
    queryKey: ['reward_balance'],
    queryFn: async () => rewardsApi.getRewardsSummary(period),
  })
}

export default useGetRewardBalance
