import { useCallback, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button, Divider, ToastMessage } from '@/components'
import { Icons } from '@/theme/Images'
import PropTypes from 'prop-types'
import { StaticAnnouncementAction } from '../../components/static-announcement'
import { useLinkToken } from '../hooks/use-link-token'
import { usePublicToken } from '../hooks/use-public-token'
import { toast } from 'sonner'
import { usePlaidLink } from 'react-plaid-link'
import Spinner from '@/assets/icons/spinner'
import { useAuth } from '@/contexts/auth.context'
import { useDismissFirstLoginModal } from '../hooks/use-dismiss-first-login-modal'

const PlaidRecurringModal = () => {
  const { user } = useAuth()
  const dismissed = user.isFirstLoginModalDismissed()

  const { mutateAsync: dismissFirstLoginModalMutate } = useDismissFirstLoginModal()

  const [isFirstModalOpen, setIsFirstModalOpen] = useState(!dismissed)
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false)

  const handleFirstModalOpenClose = val => {
    setIsFirstModalOpen(val)
  }

  const handleModalClosing = async () => {
    setIsFirstModalOpen(false)
    setIsSecondModalOpen(true)
    await dismissFirstLoginModalMutate()
  }

  const handleBackClick = () => {
    setIsSecondModalOpen(false)
    setIsFirstModalOpen(true)
  }

  const { data: linkTokenData, isFetching: isFetchingLinkToken } = useLinkToken()
  const { mutateAsync: publicTokenMutate } = usePublicToken({ monitor: true })

  const onSuccess = useCallback(
    async (public_token, { accounts }) => {
      try {
        await publicTokenMutate({ publicToken: public_token, accounts })
        toast.custom(() => (
          <ToastMessage
            success
            text="Account added successfully"
            description="Your new account is now active and can be used both to pay your balance and as a default payment method."
          />
        ))
      } catch (error) {
        toast.custom(() => (
          <ToastMessage
            text="Something went wrong"
            description="Unfortunately, we encountered an unexpected issue. Please try again, or use the 'Contact Support' button below for assistance. We’re here to help you resolve this as smoothly as possible."
          />
        ))
      }
    },
    [publicTokenMutate]
  )

  const onExit = function (err, metadata) {
    console.log(err)
    console.log(metadata)

    if (err != null) {
      toast.custom(() => (
        <ToastMessage
          text="Something went wrong"
          description="Unfortunately, we encountered an unexpected issue. Please try again, or use the 'Contact Support' button below for assistance. We’re here to help you resolve this as smoothly as possible."
        />
      ))
    }
  }

  const config = {
    token: linkTokenData?.link_token,
    onSuccess,
    onExit,
  }

  const { open, ready } = usePlaidLink(config)

  const handleGetStarted = async () => {
    setIsFirstModalOpen(false)
    open()
    await dismissFirstLoginModalMutate()
  }

  return (
    <>
      <Dialog onOpenChange={handleFirstModalOpenClose} open={isFirstModalOpen}>
        <DialogTrigger asChild>
          <div>
            <StaticAnnouncementAction text="Connect account" handleClick={() => setIsFirstModalOpen(true)} />
          </div>
        </DialogTrigger>
        <DialogContent
          className="max-w-[92%] rounded-lg md:max-w-[480px] h-auto"
          data-testid="card-dialog-content"
          onInteractOutside={handleModalClosing}
          onClose={handleModalClosing}
        >
          <div className="flex flex-row justify-start items-start p-5 pb-2">
            <img src={Icons.chainIcon} alt="icon" width={48} />
            <DialogHeader className="pt-0 pl-4 w-[215px] md:w-[368px] ">
              <div className="flex flex-col justify-center items-start ">
                <DialogTitle className="leading-7 text-dGray-900 text-lg font-inter text-left">
                  Earn Points on Your Lease & Loan Payments
                </DialogTitle>
                <DialogDescription className="text-dGray-600 text-start pt-1 w-[215px] md:w-[368px]">
                  <div className="flex flex-col justify-center items-start gap-4">
                    <div>
                      <p className="font-inter text-sm font-normal leading-5 text-left">
                        We&apos;re excited to have you in the driver&apos;s seat. Ready to maximize your journey?
                        Connect your vehicle lease or loan account to get started on earning rewards and enjoying
                        exclusive perks!
                      </p>
                    </div>
                    <div>
                      <p className="font-inter text-sm font-bold leading-5 text-left">Step 1: Connect Your Account</p>
                      <p className="font-inter text-sm font-normal leading-5 text-left">
                        Connect the bank account you use for vehicle payments via Plaid. We will monitor ONLY for
                        recurring vehicle payments. Your other account activities will remain private.
                      </p>
                    </div>
                    <div>
                      <p className="font-inter text-sm font-bold leading-5 text-left">Step 2: Make Your Payments</p>
                      <p className="font-inter text-sm font-normal leading-5 text-left">
                        Make your payment as you normally would. Continue using your regular payment account for your
                        vehicle payments.
                      </p>
                    </div>
                    <div>
                      <p className="font-inter text-sm font-bold leading-5 text-left w-[215px] md:w-[370px]">
                        Step 3: Earn Points Monthly
                      </p>
                      <p className="font-inter text-sm font-normal leading-5 text-left">
                        Earn points effortlessly. At the end of each month, if you’ve spent $500 on your Fasten™
                        Rewards Visa Card, we&apos;ll calculate the points you&apos;ve earned on up to $1,000 of your
                        lease and loan payments and apply them to your account. See [Rewards Terms & Conditions] for
                        more information.
                      </p>
                    </div>
                  </div>
                </DialogDescription>
              </div>
            </DialogHeader>
          </div>
          <Divider />
          <DialogFooter className="w-full p-5">
            <Button
              type="button"
              className="w-full"
              onClick={handleGetStarted}
              disabled={isFetchingLinkToken || !ready}
            >
              {isFetchingLinkToken || !ready ? <Spinner size="20" stroke="#98a2b3" /> : null}
              Get Started
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <SkipAddingPaymentAccountModal
        isOpen={isSecondModalOpen}
        onClose={setIsSecondModalOpen}
        onBackClick={handleBackClick}
      />
    </>
  )
}

const SkipAddingPaymentAccountModal = ({ isOpen, onClose, onBackClick }) => {
  return (
    <Dialog onOpenChange={onClose} open={isOpen}>
      <DialogContent className="max-w-[92%] rounded-lg md:max-w-[480px] h-auto" data-testid="card-dialog-content">
        <div className="flex flex-row justify-start items-start p-5 pb-2">
          <img src={Icons.chainIcon} alt="icon" width={48} />
          <DialogHeader className="pt-0 pl-4 w-[215px] md:w-[368px] ">
            <div className="flex flex-col justify-center items-start ">
              <DialogTitle className="leading-7 text-dGray-900 text-lg font-inter text-left">
                Skip Adding Payment Account
              </DialogTitle>
              <DialogDescription className="text-dGray-600 text-start pt-1 w-[215px] md:w-[368px]">
                <div className="flex flex-col justify-center items-start gap-4">
                  <div>
                    <p className="font-inter text-sm font-normal leading-5 text-left">
                      Are you sure you don&apos;t want to add your account for vehicle lease or loan payments? Without
                      this, you will not be eligible to earn rewards in this category.
                    </p>
                  </div>
                </div>
              </DialogDescription>
            </div>
          </DialogHeader>
        </div>
        <Divider />
        <DialogFooter className="w-full p-5">
          <Button variant="outline" className="w-full" onClick={onBackClick}>
            Back
          </Button>
          <Button className="w-full bg-error-700 hover:bg-error-700 focus:bg-error-700" onClick={() => onClose(false)}>
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

SkipAddingPaymentAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
}

export default PlaidRecurringModal
