import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Progress, Button } from '@/components'
import { ROUTE_REWARDS, ROUTE_REWARDS_BENEFITS } from '@/routes/paths'
import useGetRewardBalance from '../hooks/use-get-reward-balance'
import { useNavigate } from 'react-router-dom'
import RewardsSkeleton from './rewards.skeleton'
import { formatRewardsBalanceResponse } from '@/utils/response-format-utils'

const Rewards = () => {
  const navigate = useNavigate()
  const [showRewards, setShowRewards] = useState(false)

  const { isLoading, data: rewardsData } = useGetRewardBalance('quarter')

  const formattedResponse = useMemo(() => {
    return formatRewardsBalanceResponse(rewardsData?.topRewardsClasses)
  }, [rewardsData])

  if (isLoading) {
    return <RewardsSkeleton />
  }
  return (
    <div className="flex flex-col items-start md:p-5 px-5 md:mt-2">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-col items-start">
          <p className="text-lg font-semibold text-dGray-900">Points Balance</p>
          <p className="text-4xl font-semibold text-dGray-900">
            {Number(rewardsData?.pointsBalance || 0).toLocaleString()}
          </p>
        </div>
        <div
          className="flex md:hidden flex-row justify-end items-center cursor-pointer"
          onClick={() => setShowRewards(prev => !prev)}
        >
          <p className="text-md font-semibold">{`Show ${showRewards ? 'less' : 'more'}`}</p>
        </div>
      </div>
      <div
        className={`w-full mt-5 ${showRewards ? 'flex h-max' : 'hidden h-0'} h-max md:flex flex-col overflow-hidden`}
      >
        <div className="w-full bg-[#6F909E] bg-opacity-5 rounded-lg flex flex-row pt-4 pb-4 pr-4 mb-3">
          <div className="ml-5 w-full flex flex-col h-[35px] justify-between item-between">
            <div className="w-full flex justify-between items-center">
              <span className="text-[#6F909E] text-sm font-medium">{formattedResponse[0]?.category}</span>
              <span className="text-[#6F909E] text-sm font-normal" data-testid="reward-balance-value">
                {formattedResponse[0]?.points}
              </span>
            </div>
            <Progress
              value={formattedResponse[0]?.portion}
              className="h-[8px] bg-[#6F909E] bg-opacity-40"
              indicatorColor="bg-[#6F909E] rounded-full"
              data-testid="progress-bar"
            />
          </div>
        </div>
        <div className="w-full bg-[#FFA500] bg-opacity-5 md:rounded-lg flex flex-row pt-4 pb-4 pr-4 mb-3">
          <div className="ml-5 w-full flex flex-col h-[35px] justify-between item-between">
            <div className="w-full flex justify-between items-center">
              <span className="text-[#FFA500] text-sm font-medium">{formattedResponse[1]?.category}</span>
              <span className="text-[#FFA500] text-sm font-normal" data-testid="reward-balance-value">
                {formattedResponse[1]?.points}
              </span>
            </div>
            <Progress
              value={formattedResponse[1]?.portion}
              className="h-[8px] bg-[#FFA500] bg-opacity-40"
              indicatorColor="bg-[#FFA500] rounded-full"
              data-testid="progress-bar"
            />
          </div>
        </div>
        <div className="w-full bg-[#16374E] bg-opacity-5 md:rounded-lg flex flex-row pt-4 pb-4 pr-4 mb-3">
          <div className="ml-5 w-full flex flex-col h-[35px] justify-between item-between">
            <div className="w-full flex justify-between items-center">
              <span className="text-[#16374E] text-sm font-medium">{formattedResponse[2]?.category}</span>
              <span className="text-[#16374E] text-sm font-normal" data-testid="reward-balance-value">
                {formattedResponse[2]?.points}
              </span>
            </div>
            <Progress
              value={formattedResponse[2]?.portion}
              className="h-[8px] bg-[#16374E] bg-opacity-40"
              indicatorColor="bg-[#16374E] rounded-full"
              data-testid="progress-bar"
            />
          </div>
        </div>
      </div>
      <Button
        className="w-full bg-brand-600 hover:bg-brand-700 my-4 md:my-0 text-sm"
        onClick={() => navigate(`/${ROUTE_REWARDS}${ROUTE_REWARDS_BENEFITS}`)}
      >
        Explore Rewards
      </Button>
    </div>
  )
}

Rewards.prototype = {
  data: PropTypes.object,
}

export default Rewards
