import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CreditCardContainer from './credit-card-container'
import AccountSummary from './account-summary'
import Rewards from './rewards'
import TransactionsTable from './transactions-table'
import { toast } from 'sonner'
import { ToastMessage } from '../../components/toast-message'

const HomePage = () => {
  const location = useLocation()
  const mfaEnabled = location.state?.mfaEnabled
  const [isMfaEnabled] = useState(mfaEnabled)

  useEffect(() => {
    if (isMfaEnabled) {
      toast.custom(() => (
        <ToastMessage
          success
          text="Two-step verification setup successful"
          description="Two-step verification is now set up successfully. Your account has an added layer of security."
        />
      ))
    }
  }, [mfaEnabled, isMfaEnabled])

  return (
    <div className="md:px-[10%] md:pt-[2%] pb-4 bg-bgGray" data-testid="homepage-container">
      <div className="grid grid-cols-1 md:grid-cols-6 md:gap-5 w-full md:mt-5 min-h-[80vh] md:min-h-[70vh]">
        <div className="w-full h-full md:col-span-2 flex flex-col md:rounded-lg md:shadow md:border-[1px] md:border-dGray-200 border-b-dGray-200 border-b-[1px] bg-white">
          <CreditCardContainer />
          <Rewards />
        </div>
        <div className="w-full md:col-span-4 h-full md:rounded-lg md:shadow md:border-[1px] md:border-dGray-200 bg-white">
          <AccountSummary />
          <TransactionsTable />
        </div>
      </div>
    </div>
  )
}

export default HomePage
