import './styles.css'

import { Checkbox, Dialog, DialogTrigger, InputFieldContainer } from '@/components'
import { ROUTE_FORGOT_PASSWORD, ROUTE_SIGN_UP } from '@/routes/paths'
import { lazy, useState } from 'react'
import { resendSignUpCode, signIn, signInWithRedirect } from 'aws-amplify/auth/cognito'

import AppleLogo from '@/assets/icons/apple-logo'
import { Button } from '@/components/ui/button'
import GoogleLogo from '@/assets/icons/google-logo'
import { Icons } from '@/theme/Images'
import { Input } from '@/components/ui/input'
import { convertToReadableString } from '@/lib/utils'
import { removeUserDetails } from '@/storage'
import { signInSchema } from '@/schema/sign-in'
import { useAuth } from '../../contexts/auth.context'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '@/components/loading-screen'
import EyeOpen from '@/assets/icons/eye-open'
import EyeClosed from '@/assets/icons/eye-closed'

const MFADialog = lazy(() => import('./mfa-dialog'))

function SignInPage() {
  const navigate = useNavigate()
  const [phoneText, setPhoneText] = useState(null)
  const [loginNext, setLoginNext] = useState(null)
  const [loginError, setLoginError] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState()
  const { loading, setLoading } = useAuth()
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate: values => {
      const errors = {}
      if (!values.email) {
        errors.email = 'Email is required'
      }
      if (!values.password) {
        errors.password = 'Password is required'
      }
      // Clear external login error if fields are empty
      if (!values.email && !values.password) {
        setLoginError(null)
      }
      return errors
    },
    validationSchema: signInSchema,
    onSubmit: async values => {
      setLoading(true)
      removeUserDetails()
      try {
        const { isSignedIn, nextStep } = await signIn({ username: values.email, password: values.password })
        if (!isSignedIn) {
          switch (nextStep.signInStep) {
            case 'CONFIRM_SIGN_UP':
              resendSignUpCode({
                username: values.email,
                options: {
                  clientMetadata: {
                    domain: import.meta.env.VITE_APP_DOMAIN,
                  },
                },
              })
              break
            case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
              setPhoneText(nextStep.codeDeliveryDetails.destination)
              setLoginNext(nextStep.signInStep)
              setIsDialogOpen(true)
              break

            default:
              break
          }
          setLoginError(null)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setPhoneText(null)
        setLoginNext(null)
        setLoginError(error.message === 'Incorrect username or password.' ? 'Invalid credentials' : error.message)
        setLoading(false)
      }
    },
  })

  // Clear error message when any input field is focused
  const handleInputFocus = () => {
    setLoginError(null)
  }

  if (loading) return <LoadingScreen />
  return (
    <div className="flex flex-row items-center justify-end min-w-fit">
      <div className="flex flex-col items-center justify-center self-stretch w-full px-5">
        <div className="flex flex-col items-center self-stretch py-0">
          <div className="absolute flex top-8 md:left-8 bg-contain bg-no-repeat">
            <img src={Icons.fastenTextLogoBlueYellow} alt="fasten text logo" className="w-[130px] h-[34px]" />
          </div>
          <div className="flex flex-col pt-8 ">
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="flex flex-col items-center self-center gap-8 max-w-[360px] py-[70px]">
                <div className="flex flex-col items-start self-stretch gap-3">
                  <p className=" text-2xl md:text-3xl font-semibold text-[--gray-900]">Log in</p>
                </div>
                <div className="flex flex-col items-center self-stretch gap-6">
                  <div className="flex flex-col self-stretch gap-5">
                    <InputFieldContainer label="Email">
                      <Input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        autoComplete="username"
                        onFocus={handleInputFocus}
                        className={formik.errors.email ? ' border-[--error-300]' : ''}
                        {...formik.getFieldProps('email')}
                      />
                      {formik.errors.email && (
                        <p className="text-sm font-normal text-[--error-500]">{formik.errors.email}</p>
                      )}
                    </InputFieldContainer>
                    <InputFieldContainer label="Password" className="relative">
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        placeholder="Enter your password"
                        autoComplete="current-password"
                        onFocus={handleInputFocus}
                        className={formik.errors.password && formik.touched.password ? ' border-[--error-300]' : ''}
                        {...formik.getFieldProps('password')}
                        data-testid="password"
                      />
                      <button
                        type="button"
                        disabled={!formik.values.password}
                        className={`absolute right-3 ${formik.errors.password && formik.touched.password ? 'top-[39%]' : 'top-[50%]'} h-6 w-5 pl-1 bg-white`}
                        onClick={() => setShowPassword(!showPassword)}
                        data-testid="show-password-button"
                      >
                        {showPassword ? (
                          <EyeOpen stroke={`${formik.values.password ? '#98a2b3' : '#adbfc8'}`} />
                        ) : (
                          <EyeClosed stroke={`${formik.values.password ? '#98a2b3' : '#adbfc8'}`} />
                        )}
                      </button>
                      {formik.touched.password && (
                        <p className="text-sm font-normal text-[--error-500]">{formik.errors.password}</p>
                      )}
                    </InputFieldContainer>
                  </div>
                  <div className="flex items-center self-stretch w-full gap-x-8">
                    <Checkbox label="Remember for 30 days" />
                    <Button type="button" variant="link" size="text" onClick={() => navigate(ROUTE_FORGOT_PASSWORD)}>
                      Forgot password
                    </Button>
                  </div>
                  {loginError ? (
                    <p className="text-sm font-semibold text-[--error-500]">{loginError}</p>
                  ) : loginNext ? (
                    <p className="text-sm font-semibold text-[--brand-500]">{convertToReadableString(loginNext)}</p>
                  ) : null}
                  <div className="flex flex-col items-start self-stretch gap-4">
                    {phoneText ? (
                      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                        <MFADialog
                          phoneText={phoneText}
                          setPhoneText={setPhoneText}
                          email={formik.values.email}
                          password={formik.values.password}
                          onClose={() => {
                            setIsDialogOpen(false), setLoginNext(null)
                          }}
                        />
                      </Dialog>
                    ) : null}
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button type="submit" disabled={!formik.isValid || loading}>
                          Sign in
                        </Button>
                      </DialogTrigger>
                    </Dialog>
                    <Button
                      type="button"
                      variant="outline"
                      className="gap-3"
                      disabled={loading}
                      onClick={() => {
                        setLoading(true)
                        removeUserDetails()
                        setTimeout(async () => {
                          signInWithRedirect({ provider: 'Apple' })
                            // When user click login with apple and then navigate back to signin page, Loading should be cleared
                            .finally(() => setLoading(false))
                        }, 200)
                      }}
                    >
                      <AppleLogo />
                      Sign in with Apple
                    </Button>

                    <Button
                      type="button"
                      variant="outline"
                      className="gap-3"
                      disabled={loading}
                      onClick={() => {
                        setLoading(true)
                        setTimeout(async () => {
                          signInWithRedirect({ provider: 'Google' })
                            // When user click login with google and then navigate back to signin page, Loading should be cleared
                            .finally(() => setLoading(false))
                        }, 200)
                      }}
                    >
                      <GoogleLogo />
                      Sign in with Google
                    </Button>
                  </div>
                </div>
                <div className="flex content-center gap-1">
                  <p className="text-sm text-[--gray-600]">Don’t have an account?</p>
                  <Button
                    type="button"
                    variant="link"
                    size="text"
                    onClick={() => {
                      removeUserDetails()
                      navigate(ROUTE_SIGN_UP)
                    }}
                  >
                    Sign up
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="img-section min-w-[60%]">
        <div className="flex flex-col items-start gap-6 p-14">
          <p className="text-2xl font-bold text-white">Mission</p>
          <p className="text-4xl font-medium text-white -tracking-[.045em]">
            Rev up your rewards with Fasten. We make every auto-related expense count, offering returns on fuel,
            maintenance, and loan payments. For the road ahead, let your expenses drive your benefits.
          </p>
        </div>
      </div>
      <p className="fasten-footer absolute bottom-8 left-8 text-sm text-[--gray-600]">© 2024 Fasten Rewards, Inc.</p>
    </div>
  )
}

export default SignInPage
