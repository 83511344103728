import PropTypes from 'prop-types'
import { sToTime } from '@/lib/utils'
import { Button } from '@/components'
import { useTimeout } from '@/hooks'

function ResendCode({ onClick }) {
  const [timer, setTimer] = useTimeout(300)

  const handleOnClick = () => {
    onClick()
    setTimer(300)
  }

  return (
    <div className="flex flex-row">
      <Button
        variant="link"
        className="p-0 m-0 h-min underline underline-offset-4 text-[--brand-600]"
        onClick={handleOnClick}
        disabled={timer > 0}
      >
        Resend code
      </Button>
      {timer > 0 ? <p className="text-[--brand-600]">&nbsp;in {sToTime(timer)}</p> : null}
    </div>
  )
}

ResendCode.propTypes = {
  onClick: PropTypes.func,
}

export default ResendCode
