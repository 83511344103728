import fetchWithAuthorization from './fetch-with-authorization'
import { END_POINTS } from './end-points'

// NOTE: Allowed periods: quarter | month | year
export const getRewardsSummary = async period => {
  return await fetchWithAuthorization({
    path: END_POINTS.REWARDS.GET_REWARDS_SUMMARY + `?period=${period}`,
  })
}

export const getRewardActivities = async (page = 1, limit = 10) => {
  return await fetchWithAuthorization({
    path: END_POINTS.REWARDS.REWARD_ACTIVITIES + `?page=${page}&limit=${limit}`,
  })
}
export const getRedeemablePoints = async () => {
  return await fetchWithAuthorization({
    path: END_POINTS.REWARDS.REDEEMABLE_POINTS,
  })
}

export const redeemPoints = async body => {
  return await fetchWithAuthorization({
    path: END_POINTS.REWARDS.REDEEM_POINTS,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export const getRewardsPoints = async () => {
  return await fetchWithAuthorization({
    path: END_POINTS.REWARDS.GET_REWARDS_POINTS,
  })
}
