/**
 * Original response does not include proportion data and it comes empty when there is no rewards data.
 * This formats the response by including proportion data and returns data array according to various scenarios.
 * @param {object} data Response data object from back-end api.
 * @returns {array} Array which includes formatted category based rewards balances.
 */
export const formatRewardsBalanceResponse = data => {
  const total = Number(data?.pointsBalance) || 0
  const calcPortion = value => {
    return (Number(value) / total) * 100
  }

  if (!data || data?.length < 1) {
    return [
      { category: '-', points: '0', portion: 0 },
      { category: '-', points: '0', portion: 0 },
      { category: '-', points: '0', portion: 0 },
    ]
  } else if (data?.length < 2) {
    return [
      {
        category: data[0]?.description || 'Default',
        points: data[0]?.totalpoints || '0',
        portion: calcPortion(data[0]?.totalpoints),
      },
      { category: '-', points: '0', portion: 0 },
      { category: '-', points: '0', portion: 0 },
    ]
  } else if (data?.length < 3) {
    return [
      {
        category: data[0]?.description || 'Default',
        points: data[0]?.totalpoints || '0',
        portion: calcPortion(data[0]?.totalpoints || '0'),
      },
      {
        category: data[1]?.description || 'Default',
        points: data[1]?.totalpoints || '0',
        portion: calcPortion(data[1]?.totalpoints || '0'),
      },
      { category: '-', points: '0', portion: 0 },
    ]
  } else {
    return [
      {
        category: data[0]?.description || 'Default',
        points: data[0]?.totalpoints,
        portion: calcPortion(data[0]?.totalpoints || '0'),
      },
      {
        category: data[1]?.description || 'Default',
        points: data[1]?.totalpoints || '0',
        portion: calcPortion(data[1]?.totalpoints || '0'),
      },
      {
        category: data[2]?.description || 'Default',
        points: data[2]?.totalpoints || '0',
        portion: calcPortion(data[2]?.totalpoints || '0'),
      },
    ]
  }
}

/**
 * Format original response for the donut chart
 * @param {object} data Response data object from back-end api.
 * @returns {array} Array which includes formatted category based rewards balances.
 */
export const formatRewardsBalanceForDonutChart = data => {
  const total = Number(data?.totalPoints) || 0

  function capitalizeFirstLetter(string) {
    if (string) {
      return string[0]?.toUpperCase() + string.slice(1).toLowerCase()
    } else {
      return 'Default'
    }
  }
  if (!data || data?.length < 1) {
    return null
  } else if (data?.length < 6) {
    return data?.map(item => {
      return { name: capitalizeFirstLetter(item?.description), value: Number(item?.totalpoints || '0') }
    })
  } else {
    let output = data?.slice(0, 4).map(item => {
      return { name: capitalizeFirstLetter(item?.description), value: Number(item?.totalpoints || '0') }
    })
    let accumulatedTotal = output.reduce((acc, item) => acc + item.value, 0)
    output.push({ name: 'Remaining Categories', value: total - accumulatedTotal })
    return output
  }
}
